import React, { useContext, useRef } from "react";

import Slider from "react-slick";

import { FaQuoteLeft, FaArrowRight, FaArrowLeft } from "react-icons/fa";

import { ThemeContext } from "../../contexts/ThemeContext";
import { testimonialsData } from "../../data/testimonialsData";

import "./Testimonials.css";

function Testimonials() {
    const { theme } = useContext(ThemeContext);
    const sliderRef = useRef();

    const settings = {
        dots: true,
        adaptiveHeight: true,
        infinite: true,
        centerPadding: "100px",
        speed: 1000,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        margin: 3,
        loop: true,
        autoplaySpeed: 20000,
        draggable: true,
        swipeToSlide: true,
        swipe: true,
    };

    const gotoNext = () => {
        sliderRef.current.slickNext();
    };

    const gotoPrev = () => {
        sliderRef.current.slickPrev();
    };

    return (
        <>
            {testimonialsData.length > 0 && (
                <div className="testimonials" id="testimonials" style={{ backgroundColor: theme.primary }}>
                    <div className="testimonials--header">
                        <h1 style={{ color: theme.secondary }}>Testimonials</h1>
                    </div>
                    <div className="testimonials--body">
                        <FaQuoteLeft className="quote" style={{ color: theme.secondary }} />
                        <div className="testimonials--slider" style={{ backgroundColor: theme.primary }}>
                            <Slider {...settings} ref={sliderRef}>
                                {testimonialsData.map((testimonial) => (
                                    <div className="single--testimony" key={testimonial.id}>
                                        <div className="testimonials--container">
                                            <div
                                                className="review--img"
                                                style={{
                                                    backgroundColor: theme.secondary,
                                                }}
                                            >
                                                <img src={testimonial.image} alt={testimonial.name} />
                                            </div>
                                            <div
                                                className="review--content"
                                                style={{
                                                    backgroundColor: theme.secondary,
                                                    color: theme.tertiary,
                                                }}
                                            >
                                                {testimonial.text.map((text, index) => (
                                                    <p key={`${text}-${index}`}>{text}</p>
                                                ))}
                                                <h1>{testimonial.name}</h1>
                                                <h4>{testimonial.title}</h4>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                            <button className="prevBtn" onClick={gotoPrev} style={{ backgroundColor: theme.secondary }}>
                                <FaArrowLeft style={{ color: theme.primary }} aria-label="Previous testimonial" />
                            </button>
                            <button className="nextBtn" onClick={gotoNext} style={{ backgroundColor: theme.secondary }}>
                                <FaArrowRight style={{ color: theme.primary }} aria-label="Next testimonial" />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Testimonials;
