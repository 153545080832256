/* eslint-disable */
import {
    blueThemegirl,
    blueThemeboy,
    bwThemeboy,
    bwThemegirl,
    greenThemeboy,
    greenThemegirl,
    orangeThemeboy,
    orangeThemegirl,
    pinkThemeboy,
    pinkThemegirl,
    purpleThemeboy,
    purpleThemegirl,
    redThemeboy,
    redThemegirl,
    yellowThemeboy,
    yellowThemegirl,
    eduBlack,
    eduBlue,
    eduGreen,
    eduOrange,
    eduPink,
    eduPurple,
    eduRed,
    eduYellow,
    expBlack,
    expBlue,
    expGreen,
    expOrange,
    expPink,
    expPurple,
    expRed,
    expYellow,
    contactsBlack,
    contactsBlue,
    contactsGreen,
    contactsOrange,
    contactsPink,
    contactsPurple,
    contactsRed,
    contactsYellow,
} from "./images";

export const greenThemeLight = {
    type: "light",
    primary: "#3fc337",
    primary400: "#57d750",
    primary600: "#29c51f",
    primary80: "#3fc337cc",
    primary50: "#3fc33780",
    primary30: "#3fc3374d",
    secondary: "#EAEAEA",
    secondary70: "#EAEAEAb3",
    secondary50: "#EAEAEA80",
    tertiary: "#212121",
    tertiary80: "#212121cc",
    tertiary70: "#212121b3",
    tertiary50: "#21212180",
    aboutimg1: greenThemeboy,
    aboutimg2: greenThemegirl,
    eduimg: eduGreen,
    expimg: expGreen,
    contactsimg: contactsGreen,
};

export const greenThemeDark = {
    type: "dark",
    primary: "#3fc337",
    primary400: "#57d750",
    primary600: "#29c51f",
    primary80: "#3fc337cc",
    primary50: "#3fc33780",
    primary30: "#3fc3374d",
    secondary: "#212121",
    secondary70: "#212121b3",
    secondary50: "#21212180",
    tertiary: "#eaeaea",
    tertiary80: "#eaeaeacc",
    tertiary70: "#eaeaeab3",
    tertiary50: "#eaeaea80",
    aboutimg1: greenThemeboy,
    aboutimg2: greenThemegirl,
    eduimg: eduGreen,
    expimg: expGreen,
    contactsimg: contactsGreen,
};
export const bwThemeLight = {
    type: "light",
    primary: "#000000",
    primary400: "#000000",
    primary600: "#000000",
    primary80: "#000000cc",
    primary50: "#00000080",
    primary30: "#0000004d",
    secondary: "#EAEAEA",
    secondary70: "#EAEAEAb3",
    secondary50: "#EAEAEA80",
    tertiary: "#212121",
    tertiary80: "#212121cc",
    tertiary70: "#212121b3",
    tertiary50: "#21212180",
    aboutimg1: bwThemeboy,
    aboutimg2: bwThemegirl,
    eduimg: eduBlack,
    expimg: expBlack,
    contactsimg: contactsBlack,
};
export const bwThemeDark = {
    type: "dark",
    primary: "#B6B6B6",
    primary400: "#B6B6B6",
    primary600: "#B6B6B6",
    primary80: "#B6B6B6cc",
    primary50: "#B6B6B680",
    primary30: "#B6B6B64d",
    secondary: "#212121",
    secondary70: "#212121b3",
    secondary50: "#21212180",
    tertiary: "#eaeaea",
    tertiary80: "#eaeaeacc",
    tertiary70: "#eaeaeab3",
    tertiary50: "#eaeaea80",
    aboutimg1: bwThemeboy,
    aboutimg2: bwThemegirl,
    eduimg: eduBlack,
    expimg: expBlack,
    contactsimg: contactsBlack,
};

export const blueThemeLight = {
    type: "light",
    primary: "#545fc4",
    primary400: "#6e76c7",
    primary600: "#3644c9",
    primary80: "#545fc4cc",
    primary50: "#545fc480",
    primary30: "#545fc44d",
    secondary: "#eaeaea",
    secondary70: "#eaeaeab3",
    secondary50: "#eaeaea80",
    tertiary: "#212121",
    tertiary80: "#212121cc",
    tertiary70: "#212121b3",
    tertiary50: "#21212180",
    aboutimg1: blueThemeboy,
    aboutimg2: blueThemegirl,
    eduimg: eduBlue,
    expimg: expBlue,
    contactsimg: contactsBlue,
};
export const blueThemeDark = {
    type: "dark",
    primary: "#545fc4",
    primary400: "#6e76c7",
    primary600: "#3644c9",
    primary80: "#545fc4cc",
    primary50: "#545fc480",
    primary30: "#545fc44d",
    secondary: "#212121",
    secondary70: "#212121b3",
    secondary50: "#21212180",
    tertiary: "#eaeaea",
    tertiary80: "#eaeaeacc",
    tertiary70: "#eaeaeab3",
    tertiary50: "#eaeaea80",
    aboutimg1: blueThemeboy,
    aboutimg2: blueThemegirl,
    eduimg: eduBlue,
    expimg: expBlue,
    contactsimg: contactsBlue,
};

export const redThemeLight = {
    type: "light",
    primary: "#f03939",
    primary400: "#dd4d4d",
    primary600: "#ea2424",
    primary80: "#f03939cc",
    primary50: "#f0393980",
    primary30: "#f039394d",
    secondary: "#eaeaea",
    secondary70: "#eaeaeab3",
    secondary50: "#eaeaea80",
    tertiary: "#212121",
    tertiary80: "#212121cc",
    tertiary70: "#212121b3",
    tertiary50: "#21212180",
    aboutimg1: redThemeboy,
    aboutimg2: redThemegirl,
    eduimg: eduRed,
    expimg: expRed,
    contactsimg: contactsRed,
};
export const redThemeDark = {
    type: "dark",
    primary: "#f03939",
    primary400: "#dd4d4d",
    primary600: "#ea2424",
    primary80: "#f03939cc",
    primary50: "#f0393980",
    primary30: "#f039394d",
    secondary: "#212121",
    secondary70: "#212121b3",
    secondary50: "#21212180",
    tertiary: "#eaeaea",
    tertiary80: "#eaeaeacc",
    tertiary70: "#eaeaeab3",
    tertiary50: "#eaeaea80",
    aboutimg1: redThemeboy,
    aboutimg2: redThemegirl,
    eduimg: eduRed,
    expimg: expRed,
    contactsimg: contactsRed,
};

export const orangeThemeLight = {
    type: "light",
    primary: "#f56539",
    primary400: "#ff764d",
    primary600: "#fa5624",
    primary80: "#f56539cc",
    primary50: "#f5653980",
    primary30: "#f565394d",
    secondary: "#eaeaea",
    secondary70: "#eaeaeab3",
    secondary50: "#eaeaea80",
    tertiary: "#212121",
    tertiary80: "#212121cc",
    tertiary70: "#212121b3",
    tertiary50: "#21212180",
    aboutimg1: orangeThemeboy,
    aboutimg2: orangeThemegirl,
    eduimg: eduOrange,
    expimg: expOrange,
    contactsimg: contactsOrange,
};
export const orangeThemeDark = {
    type: "dark",
    primary: "#f56539",
    primary400: "#ff764d",
    primary600: "#fa5624",
    primary80: "#f56539cc",
    primary50: "#f5653980",
    primary30: "#f565394d",
    secondary: "#212121",
    secondary70: "#212121b3",
    secondary50: "#21212180",
    tertiary: "#eaeaea",
    tertiary80: "#eaeaeacc",
    tertiary70: "#eaeaeab3",
    tertiary50: "#eaeaea80",
    aboutimg1: orangeThemeboy,
    aboutimg2: orangeThemegirl,
    eduimg: eduOrange,
    expimg: expOrange,
    contactsimg: contactsOrange,
};

export const purpleThemeLight = {
    type: "light",
    primary: "#823ae0",
    primary400: "#9456e5",
    primary600: "#762ad9",
    primary80: "#823ae0cc",
    primary50: "#823ae080",
    primary30: "#823ae04d",
    secondary: "#eaeaea",
    secondary70: "#eaeaeab3",
    secondary50: "#eaeaea80",
    tertiary: "#212121",
    tertiary80: "#212121cc",
    tertiary70: "#212121b3",
    tertiary50: "#21212180",
    aboutimg1: purpleThemeboy,
    aboutimg2: purpleThemegirl,
    eduimg: eduPurple,
    expimg: expPurple,
    contactsimg: contactsPurple,
};
export const purpleThemeDark = {
    type: "dark",
    primary: "#823ae0",
    primary400: "#9456e5",
    primary600: "#762ad9",
    primary80: "#823ae0cc",
    primary50: "#823ae080",
    primary30: "#823ae04d",
    secondary: "#212121",
    secondary70: "#212121b3",
    secondary50: "#21212180",
    tertiary: "#eaeaea",
    tertiary80: "#eaeaeacc",
    tertiary70: "#eaeaeab3",
    tertiary50: "#eaeaea80",
    aboutimg1: purpleThemeboy,
    aboutimg2: purpleThemegirl,
    eduimg: eduPurple,
    expimg: expPurple,
    contactsimg: contactsPurple,
};

export const pinkThemeLight = {
    type: "light",
    primary: "#ff4f93",
    primary400: "#e56f9d",
    primary600: "#e14381",
    primary80: "#ff4f93cc",
    primary50: "#ff4f9380",
    primary30: "#ff4f934d",
    secondary: "#eaeaea",
    secondary70: "#eaeaeab3",
    secondary50: "#eaeaea80",
    tertiary: "#212121",
    tertiary80: "#212121cc",
    tertiary70: "#212121b3",
    tertiary50: "#21212180",
    aboutimg1: pinkThemeboy,
    aboutimg2: pinkThemegirl,
    eduimg: eduPink,
    expimg: expPink,
    contactsimg: contactsPink,
};
export const pinkThemeDark = {
    type: "dark",
    primary: "#ff4f93",
    primary400: "#e56f9d",
    primary600: "#e14381",
    primary80: "#ff4f93cc",
    primary50: "#ff4f9380",
    primary30: "#ff4f934d",
    secondary: "#212121",
    secondary70: "#212121b3",
    secondary50: "#21212180",
    tertiary: "#eaeaea",
    tertiary80: "#eaeaeacc",
    tertiary70: "#eaeaeab3",
    tertiary50: "#eaeaea80",
    aboutimg1: pinkThemeboy,
    aboutimg2: pinkThemegirl,
    eduimg: eduPink,
    expimg: expPink,
    contactsimg: contactsPink,
};

// active theme
export const yellowThemeLight = {
    type: "light",
    primary: "#E9AD35",
    primary400: "#e8b44c",
    primary600: "#eeac2a",
    primary800: "#875a00",
    primary1000: "#452e00",
    primary80: "#E9AD35cc",
    primary50: "#E9AD3580",
    primary30: "#E9AD354d",
    secondary: "#eaeaea",
    secondary70: "#eaeaeab3",
    secondary50: "#eaeaea80",
    tertiary: "#212121",
    tertiary80: "#212121cc",
    tertiary70: "#212121b3",
    tertiary50: "#21212180",
    aboutimg1: yellowThemeboy,
    aboutimg2: yellowThemegirl,
    eduimg: eduYellow,
    expimg: expYellow,
    contactsimg: contactsYellow,
};

export const yellowThemeDark = {
    type: "dark",
    primary: "#E9AD35",
    primary400: "#e8b44c",
    primary600: "#eeac2a",
    primary80: "#E9AD35cc",
    primary50: "#E9AD3580",
    primary30: "#E9AD354d",
    secondary: "#212121",
    secondary70: "#212121b3",
    secondary50: "#21212180",
    tertiary: "#eaeaea",
    tertiary80: "#eaeaeacc",
    tertiary70: "#eaeaeab3",
    tertiary50: "#eaeaea80",
    aboutimg1: yellowThemeboy,
    aboutimg2: yellowThemegirl,
    eduimg: eduYellow,
    expimg: expYellow,
    contactsimg: contactsYellow,
};
