import { stringifySrcset } from "srcset";
import valenciaRunningHalfMarathon_jwu9ri_c_scale_w_200 from "../assets/jpg/hobbies/valenciaRunningHalfMarathon/valenciaRunningHalfMarathon_jwu9ri_c_scale,w_200.jpg";
import valenciaRunningHalfMarathon_jwu9ri_c_scalew_1063 from "../assets/jpg/hobbies/valenciaRunningHalfMarathon/valenciaRunningHalfMarathon_jwu9ri_c_scale,w_1063.jpg";
import valenciaRunningHalfMarathon_jwu9ri_c_scalew_1400 from "../assets/jpg/hobbies/valenciaRunningHalfMarathon/valenciaRunningHalfMarathon_jwu9ri_c_scale,w_1400.jpg";
import valenciaRunningHalfMarathon_jwu9ri_c_scalew_747 from "../assets/jpg/hobbies/valenciaRunningHalfMarathon/valenciaRunningHalfMarathon_jwu9ri_c_scale,w_747.jpg";
import valenciaRunningHalfMarathon_jwu9ri_c_scalew_1172 from "../assets/jpg/hobbies/valenciaRunningHalfMarathon/valenciaRunningHalfMarathon_jwu9ri_c_scale,w_1172.jpg";
import valenciaRunningHalfMarathon_jwu9ri_c_scalew_200 from "../assets/jpg/hobbies/valenciaRunningHalfMarathon/valenciaRunningHalfMarathon_jwu9ri_c_scale,w_200.jpg";
import valenciaRunningHalfMarathon_jwu9ri_c_scalew_865 from "../assets/jpg/hobbies/valenciaRunningHalfMarathon/valenciaRunningHalfMarathon_jwu9ri_c_scale,w_865.jpg";
import valenciaRunningHalfMarathon_jwu9ri_c_scalew_1278 from "../assets/jpg/hobbies/valenciaRunningHalfMarathon/valenciaRunningHalfMarathon_jwu9ri_c_scale,w_1278.jpg";
import valenciaRunningHalfMarathon_jwu9ri_c_scalew_380 from "../assets/jpg/hobbies/valenciaRunningHalfMarathon/valenciaRunningHalfMarathon_jwu9ri_c_scale,w_380.jpg";
import valenciaRunningHalfMarathon_jwu9ri_c_scalew_954 from "../assets/jpg/hobbies/valenciaRunningHalfMarathon/valenciaRunningHalfMarathon_jwu9ri_c_scale,w_954.jpg";
import valenciaRunningHalfMarathon_jwu9ri_c_scalew_1386 from "../assets/jpg/hobbies/valenciaRunningHalfMarathon/valenciaRunningHalfMarathon_jwu9ri_c_scale,w_1386.jpg";
import valenciaRunningHalfMarathon_jwu9ri_c_scalew_566 from "../assets/jpg/hobbies/valenciaRunningHalfMarathon/valenciaRunningHalfMarathon_jwu9ri_c_scale,w_566.jpg";

import spanishGP_1372 from "../assets/jpg/hobbies/spanishGP/spanishGP_dbd1y2_c_scale,w_1372.jpg";
import spanishGP_1032 from "../assets/jpg/hobbies/spanishGP/spanishGP_dbd1y2_c_scale,w_1032.jpg";
import spanishGP_200 from "../assets/jpg/hobbies/spanishGP/spanishGP_dbd1y2_c_scale,w_200.jpg";
import spanishGP_654 from "../assets/jpg/hobbies/spanishGP/spanishGP_dbd1y2_c_scale,w_654.jpg";
import spanishGP_1358 from "../assets/jpg/hobbies/spanishGP/spanishGP_dbd1y2_c_scale,w_1358.jpg";
import spanishGP_1400 from "../assets/jpg/hobbies/spanishGP/spanishGP_dbd1y2_c_scale,w_1400.jpg";
import spanishGP_472 from "../assets/jpg/hobbies/spanishGP/spanishGP_dbd1y2_c_scale,w_472.jpg";
import spanishGP_828 from "../assets/jpg/hobbies/spanishGP/spanishGP_dbd1y2_c_scale,w_828.jpg";

const srcsetValenciaRunningHalfMarathon = stringifySrcset([
    {
        url: valenciaRunningHalfMarathon_jwu9ri_c_scale_w_200,
        width: 200,
    },
    {
        url: valenciaRunningHalfMarathon_jwu9ri_c_scalew_1063,
        width: 1063,
    },
    {
        url: valenciaRunningHalfMarathon_jwu9ri_c_scalew_1400,
        width: 1400,
    },
    {
        url: valenciaRunningHalfMarathon_jwu9ri_c_scalew_747,
        width: 747,
    },
    {
        url: valenciaRunningHalfMarathon_jwu9ri_c_scalew_1172,
        width: 1172,
    },
    {
        url: valenciaRunningHalfMarathon_jwu9ri_c_scalew_200,
        width: 200,
    },
    {
        url: valenciaRunningHalfMarathon_jwu9ri_c_scalew_865,
        width: 865,
    },
    {
        url: valenciaRunningHalfMarathon_jwu9ri_c_scalew_1278,
        width: 1278,
    },
    {
        url: valenciaRunningHalfMarathon_jwu9ri_c_scalew_380,
        width: 380,
    },
    {
        url: valenciaRunningHalfMarathon_jwu9ri_c_scalew_954,
        width: 954,
    },
    {
        url: valenciaRunningHalfMarathon_jwu9ri_c_scalew_1386,
        width: 1386,
    },
    {
        url: valenciaRunningHalfMarathon_jwu9ri_c_scalew_566,
        width: 566,
    },
]);
const srcsetSpanishGP = stringifySrcset([
    {
        url: valenciaRunningHalfMarathon_jwu9ri_c_scale_w_200,
        width: 200,
    },
    {
        url: spanishGP_1372,
        width: 1032,
    },
    {
        url: spanishGP_1032,
        width: 1372,
    },
    {
        url: spanishGP_200,
        width: 200,
    },
    {
        url: spanishGP_654,
        width: 654,
    },
    {
        url: spanishGP_1358,
        width: 1358,
    },
    {
        url: spanishGP_1400,
        width: 1400,
    },
    {
        url: spanishGP_472,
        width: 472,
    },
    {
        url: spanishGP_828,
        width: 828,
    },
]);
export const hobbiesData = [
    {
        id: 1,
        name: "Running",
        title: "Middle Distance Running",
        text: "What started out as a means to lose weight and keep healthy, led to 8 years of passionate sport.  When I started running back in 2014, little did I know that running would become such an integral part of my routine.  I have competed in several road races locally in Malta and also internationally.  Several half marathons have led me to push my limits to the maximum and challenge my mind not to give up in dire situations.  Running has helped me boost my mental agility and performance while improving my self esteem.  In turn, these benefits have spilled over to other areas, such as my professional and personal life.",
        image: valenciaRunningHalfMarathon_jwu9ri_c_scale_w_200,
        srcset: srcsetValenciaRunningHalfMarathon,
    },
    {
        id: 2,
        name: "Motorsport",
        title: "Formula 1 and British motorsport",
        text: "I am also an avid fan of Formula 1 and British motorsport.  I have myself driven at the Silverstone Race Track and have certainly learnt to appreciate the dedication and technicalities involved in such disciplines. The amount of teamwork and dedication in this sport keeps me driven to persist in working well within a team.  Through this I have learnt that one can achieve impressive results when managing to combine raw-talent together with teamwork. This is certainly a hard combination to beat.",
        image: valenciaRunningHalfMarathon_jwu9ri_c_scale_w_200,
        srcset: srcsetSpanishGP,
    },
];
