import { commonData } from "./commonData";

export const blogData = [
    {
        id: 1,
        title: "PRICE NEGOTIABLE",
        description: "Mosta Corner Penthouse: €430,000 - Freehold - Direct from owner - No agents fees - 79678899",
        date: "READ MORE ...",
        image: commonData.mostaCover,
        url: commonData.mostaPdf,
    },
];

